import { logFirebaseEvent } from 'analytics/firebase';
import {
  sendGoogleAnalytics,
  sendYandexAnalytics,
} from 'analytics/sendAnalytics';
import cn from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import type { FC, MouseEvent, ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MIN_WINDOW_WITH_FOR_AUTH_MODAL_AND_BURGER } from 'constants/numbers';
import { pageUrls } from 'constants/pagesUrls';

import { Button } from 'components/UIKit/Button';
import { Illustration } from 'components/basic/Illustration';
import { BodyL400, BodyL700, H3 } from 'components/basic/Typography';
import { Modal } from 'components/complex/Modal';

import type { TAppState } from 'sp-redux';
import { openModal } from 'sp-redux/slices/ui';
import { EUiModalTypes } from 'sp-redux/slices/ui/types';

import { isBrowser } from 'utils/environment';
import { typografFixText } from 'utils/typograf';

import { Titles } from '../Titles';
import { FifthStory } from './FifthStory';
import { FirstStory } from './FirstStory';
import { FourthStory } from './FourthStory';
import { SecondStory } from './SecondStory';
import { ThirdStory } from './ThirdStory';
import { SUCCESS_STORIES } from './constants';

import s from './SuccessStoriesBlock.module.scss';

export const SuccessStoriesBlock: FC = () => {
  const [currentStoryId, setCurrentStory] = useState(0);
  const dispatch = useDispatch();
  const router = useRouter();

  const { userInfo } = useSelector((appState: TAppState) => ({
    userInfo: appState.userInfo,
  }));

  const getSource = useMemo(() => {
    return [
      'main_write_your_story',
      'main_success_story_batluk',
      'main_success_story_polyakov',
      'main_success_story_evdokimova',
      'main_success_story_smolina',
      'main_success_story_chinkov',
    ][currentStoryId];
  }, [currentStoryId]);

  const eventParams = {
    source: getSource,
    user_type: userInfo.isLogged ? 'auth' : 'no_auth',
  };

  const handleSendAnalytics = (): void => {
    const action = 'main_click_become_author';
    logFirebaseEvent(action, eventParams);
    sendGoogleAnalytics({
      action,
    });
    sendYandexAnalytics({
      action,
      goalParams: {
        ...eventParams,
        user_id: userInfo.profile.uuid ?? '',
      },
    });
  };

  const openSignUpModalHandler = (
    evt: MouseEvent<HTMLButtonElement & HTMLAnchorElement>,
  ): void => {
    if (
      isBrowser &&
      window.innerWidth >= MIN_WINDOW_WITH_FOR_AUTH_MODAL_AND_BURGER &&
      !router.asPath.includes(pageUrls.account.registration)
    ) {
      evt.preventDefault();
      dispatch(openModal(EUiModalTypes.signUp));
      logFirebaseEvent('popup_registration_view', {
        source: 'registration_form',
      });
      handleSendAnalytics();
    }
  };

  const handleStoryClick = (id: number): void => {
    setCurrentStory(id);
  };

  const handleModalClose = (): void => {
    setCurrentStory(0);
  };

  const getFullStory = (): ReactNode => {
    const StoryToShow = [
      FirstStory,
      SecondStory,
      ThirdStory,
      FourthStory,
      FifthStory,
    ][currentStoryId - 1];
    return <StoryToShow />;
  };

  const handleButtonPublishClick = (): void => {
    handleSendAnalytics();
    router.push(pageUrls.books.index);
  };

  return (
    <>
      <Titles
        title="Истории успеха"
        subtitle="Сотни авторов уже раскрыли свой потенциал с издательской платформой Литрес"
      />

      <div className={s.blocks}>
        {SUCCESS_STORIES.map(({ id, text, name, genre, src }) => (
          <div
            key={id}
            className={s.block}
            onClick={(): void => handleStoryClick(id)}
          >
            <div className={cn(s.text, s.textHover)}>
              <Illustration
                imageType="arrowDiagonal"
                width={24}
                height={24}
                className={s.icon}
              />
              <H3>{typografFixText(text)}</H3>
            </div>
            <div className={s.about}>
              <Image src={src} height={56} width={56} alt={name} />
              <div className={s.info}>
                <BodyL700>{name}</BodyL700>
                <BodyL400 className={s.genre}>{genre}</BodyL400>
              </div>
            </div>
          </div>
        ))}
        <div className={cn(s.block, s.invite)}>
          <H3 className={s.inviteText}>Напиши свою историю</H3>
          {!userInfo.isLogged && (
            <Button
              href="/account/registration/"
              onClick={openSignUpModalHandler}
              target="_self"
            >
              Стать автором
            </Button>
          )}

          {userInfo.isLogged && (
            <Button onClick={handleButtonPublishClick}>Опубликовать</Button>
          )}
        </div>

        <Modal
          isOpen={!!currentStoryId}
          onRequestClose={handleModalClose}
          shouldCloseOnOverlayClick
          shouldCloseOnEsc
          className={s.modal}
        >
          <div className={s.modalWrapper}>
            {currentStoryId && getFullStory()}

            <div className={s.modalInvite}>
              <H3 className={s.modalInviteText}>
                30 000 000 читателей ждут вашу историю
              </H3>
              {!userInfo.isLogged && (
                <Button
                  href="/account/registration/"
                  onClick={openSignUpModalHandler}
                  target="_self"
                  className={s.modalButton}
                >
                  Стать автором
                </Button>
              )}

              {userInfo.isLogged && (
                <Button
                  onClick={handleButtonPublishClick}
                  className={s.modalButton}
                >
                  Опубликовать
                </Button>
              )}
            </div>
          </div>
        </Modal>
      </div>
    </>
  );
};
